/* You can add global styles to this file, and also import other style files */

@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap);

 @font-face {
    font-family: Inter;
    font-style: normal;
   font-weight: 300;
    font-display: swap;
    src: url(assets/fonts/inter-light.woff2) format("woff2"), url(assets/fonts/inter-light.woff) format("woff")
 };

 @font-face {
   font-family: Inter;
   font-style: normal;
   font-weight: 400;
   font-display: swap;
   src: url(assets/fonts/inter-regular.woff2) format("woff2"), url(assets/fonts/inter-regular.woff) format("woff")
 };

 @font-face {
   font-family: Inter;
   font-style: normal;
   font-weight: 500;
   font-display: swap;
   src: url(assets/fonts/inter-medium.woff2) format("woff2"), url(assets/fonts/inter-medium.woff) format("woff")
 };

 @font-face {
   font-family: Inter;
   font-style: normal;
   font-weight: 700;
   font-display: swap;
   src: url(assets/fonts/inter-bold.woff2) format("woff2"), url(assets/fonts/inter-bold.woff2) format("woff")
 };

 .authentication-bg {background-image: url(assets/images/authentication-bg.jpg);}

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@ng-select/ng-select/themes/material.theme.css";

$primary-color:#FC73AE;

// #FFE1AE
// #06A7E1
// #68A738
// #613B97
// #F57F20
// #EE3D97
// #ED1A36

a:not([href]) {
  cursor: pointer;
}
// .error-msg {
//   position: absolute;
// }
.password_box .ng-invalid,.form-group select.is-invalid {
  background-image: none;
}
/*****  start Checkbox *****/
.checkbox-box {
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
}

.checkbox-box span {
  font-size: 0px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  display: inline-block;
}

.checkbox-box span:after {
  top: 0px;
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #c1c1c1;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
}

.checkbox-box input {
  position: absolute;
  width: 20px !important;
  left: 0px;
  opacity: 0;
  z-index: +9999;
  height: 20px;
  cursor: pointer;
  margin: 0px !important;
}

.multiple-d span.ng-arrow-wrapper {
    margin-bottom: 10px;
}

.checkbox-box .checkbox-box input[type=checkbox]:checked+span:after {
  border-color: $primary-color;
}

.checkbox-box span:before {
  top: 0px;
  font-family: remixicon !important;
  content: "\eb74";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0px;
  z-index: 9;
  margin: 0;
  opacity: 0;
  text-align: center;
  line-height: 25px;
  // font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 16px;
  line-height: 20px;
  color: #28a745;
}

.checkbox-box input[type=checkbox]:checked+span:before {
  opacity: 1;
}

.checkbox-false span:before {
  content: "\F1398";
  color: #ff3d60 !important;
}

.action-checkbox {
  display: flex;

}

/***** End Checkbox *****/

/*****  start Radio box *****/
.radio_label .radio-box {
  // width: 20px;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
  // height: 20px;
  display: inline-block;
}

.radio_label .radio-box span {
  display: inline-block;
  padding-left: 30px;
  font-size: .9rem;
}

.radio_label .radio-box span:after {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #ffff;
  border-radius: 60px;
  border: 1px solid #c1c1c1;
}

.radio_label .radio-box span:before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  position: absolute;
  left: 4px;
  top: 4px;
  background: #cecece;
  border-radius: 60px;
  z-index: 9;
  opacity: 0;
}

.radio_label .radio-box input {
  position: absolute;
  width: 20px;
  left: 0px;
  opacity: 0;
  z-index: +9999;
  height: 20px;
  cursor: pointer;
}

.radio_label .radio-box input[type=radio]:checked+span:before {
  opacity: 1;
  background-color: $primary-color;
}

.radio_label .radio-box input[type=radio]:checked+span:after {
  border-color: $primary-color;
}

.radio_label .radio-box {
  margin-right: 15px;
  margin-bottom: 10px;
}

.radio_label .radio-box:last-child {
  margin-right: 0px;
}

/***** End Radio kbox *****/

/*****  start Switch *****/
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin: 0px;
}

.switch input {
  display: none;
}

.switch .slider.round {
  border-radius: 26px;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.switch .slider.round:before {
  border-radius: 50%;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch input:checked+.slider {
  background-color: #28a745;
}

.switch .checkbox label::after {
  width: auto;
}

/***** End Switch *****/

/*****  start Choose file upload  *****/
.custom_file input {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .94rem + 2px);
  margin-bottom: 0;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  outline: none;
}

.custom_file input::-webkit-file-upload-button {
  border: 0px !important;
  background: #ced4da !important;
  height: 37px !important;
  outline: none;
}

.file_img {
  height: 35px;
  width: 35px;
  border-radius: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.custom_img_input input {
  width: calc(100% - 35px);
  margin-right: 10px;
}

.custom_img_input {
  display: flex;
}

/***** End Choose file upload *****/

/*****  start Alert Popup  *****/
.ion-overlay-wrapper {
  border-radius: 13px;
  overflow: hidden;
  display: flex;
  background: #f9f9f9;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 250px;
  display: inline-block;
  width: 250px;
  font-family: Poppins, sans-serif;
}

.sc-ion-alert-ios-s {
  height: 100%;
  width: 100%;
}

.alert-head.sc-ion-alert-ios {
  padding-top: 12px;
  padding-bottom: 7px;
  text-align: center;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

.alert-head.sc-ion-alert-ios h2 {
  margin-top: 8px;
  color: #000;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  padding: 0px;
  margin-bottom: 0px;
}

.alert-message.sc-ion-alert-ios {
  -webkit-padding-start: 16px;
  padding-inline-start: 16px;
  -webkit-padding-end: 16px;
  padding-inline-end: 16px;
  padding-top: 0;
  padding-bottom: 21px;
  color: #000;
  font-size: 12px;
  text-align: center;
  font-family: Poppins, sans-serif;
}

button.alert-button {
  outline: none;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  min-width: 50%;
  height: 44px;
  border-top: 0.55px solid #0000003b;
  border-right: 0.55px solid #0000003b;
  background-color: transparent;
  color: $primary-color;
  font-size: 17px;
  /* overflow: hidden; */
  border-bottom: 0px !important;
  border-left: 0;
  font-weight: bold;
}

.alert-button-group.sc-ion-alert-ios {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  margin-right: unset;
  -webkit-margin-end: -0.55px;
  margin-inline-end: -0.55px;
  margin-right: -0.55px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

button.alert-button+button.alert-button {
  border-right: 0px !important;
  border-left: 0px !important;
}

.sc-ion-alert-ios-s .sc-ion-alert-ios-backdrop {
  background: #00000054;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 999;
}

.alert_popup .modal-content {
  background: none;
  border: 0px;
}

/*****  End Alert Popup  *****/

/*****  start Sidebar menu *****/
#sidebar-menu ul li a {
  text-transform: capitalize;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background: #293041;
  color: #d7e4ec;
}

.metismenu li.active a *,
.metismenu li:hover a *,
#sidebar-menu ul li ul.sub-menu li.active a {
  color: #ffff !important;
}


@media (max-width: 992px) {
  .sidebar-enable .vertical-menu {
    display: block;
    width: 240px !important;
    left: 0px !important;
    transition: all 0.3s;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a span {
    display: contents;
    padding-left: 0px;
  }

  div#layout-wrapper .vertical-menu {
    display: block;
    left: -300px;
    transition: all 0.3s;
    z-index: 999;
    position: fixed;
  }

  body #layout-wrapper .navbar-brand-box {
    width: 73px !important;
  }

  .navbar-brand-box {
    display: block !important;
  }

  .vertical-collpsed .footer {
    left: 0px !important;
  }

  .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
    background: transparent !important;
    width: auto !important;
  }
}

/*****  End Sidebar menu  *****/
/***** pagination-template  *****/
pagination-template ul.ngx-pagination li {
  width: 34px;
  cursor: pointer !important;
  height: 34px;
  padding: 0px !important;
  text-align: center;
  line-height: 34px;
  border-radius: 60px !important;
  margin: 0 3px;
}

pagination-template ul.ngx-pagination li.pagination-next,
pagination-template ul li.pagination-previous span,
pagination-template ul li.pagination-next a,
pagination-template ul li.pagination-previous span,
pagination-template ul.ngx-pagination li.pagination-previous a {
  font-size: 0px !important;
}

.ngx-pagination .pagination-previous a:before,
.ngx-pagination .pagination-previous.disabled:before {
  content: "\F0141" !important;
  font-family: "Material Design Icons" !important;
}

pagination-template ul li.pagination-next a:after,
pagination-template ul.ngx-pagination li.pagination-previous a:before {
  font-size: .9rem;
}

pagination-template ul.ngx-pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
  padding: 0px;
  display: flex;
}

pagination-template ul.ngx-pagination li a {
  padding: 0px !important;
  display: inline-block;
  line-height: 25px;
}

pagination-template ul.ngx-pagination li a:after {
  line-height: 34px;
  margin: 0px !important;
}

pagination-template ul.ngx-pagination li a .show-for-sr {
  display: none;
}

pagination-template ul.ngx-pagination li.current {
  background-color: $primary-color;
  cursor: default !important;
}

pagination-template ul.ngx-pagination li:hover {
  background-color: $primary-color;
}

pagination-template ul.ngx-pagination li:hover a {
  background: no-repeat;
  color: #fff;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  margin: 0px !important;
}

.ngx-pagination .pagination-next a:after,
.ngx-pagination .pagination-next.disabled:after {
  content: "\F0142" !important;
  font-family: "Material Design Icons" !important;
  font-size: 0.9rem;
}


/*****  End pagination  *****/
.auth-form-group-custom .form-control {
  padding-right: 44px;
}

.auth-form-group-custom .auti-custom-eye {
  left: auto;
  right: 34px;
  cursor: pointer;
}

.full-button {
  min-width: 100% !important;
}

body {
  overflow-x: hidden;
}

.copyright a {
  color: #505d69;
}

.password_box {
  position: relative;
}

.password_box .auti-custom-eye {
  left: auto;
  right: 12px;
  cursor: pointer;
  color: $primary-color !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
}

/***** Colors *****/
.custom-control-input:checked~.custom-control-label::before,
.page-item.active .page-link {
  border-color: $primary-color;
  background-color: $primary-color;
}

.btn-primary {
  background: $primary-color !important;
  border-color: $primary-color !important;
}

.text-primary,
body a.text-primary:focus,
body a.text-primary:hover,
.text-solitud:hover {
  color: $primary-color !important;
}

.auth-form-group-custom .auti-custom-input-icon {
  color: $primary-color !important;
}

.btn-warning,
.custom-accordion-arrow .card-header .accor-arrow-icon {
  box-shadow: none !important;
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

/***** apexcharts *****/
// .apexcharts-data-labels text {
// 	fill: #fff !important;
// 	display: none;
// }
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}

.dropdown-item:not([href]) {
  cursor: pointer;
  color: #212529;
}

.float-right .custom-select {
  z-index: +99;
  position: relative;
  cursor: pointer;
}

.apexcharts-legend.position-bottom.apexcharts-align-center {
  margin-top: 10px !important;
}

.apx_chart {
  float: left;
  width: 100%;
}

.btn-w {
  padding: 5px 20px;
  margin-left: 15px;
}

.btn_style {
  background: none;
  border: 0px;
  padding: 0px;
}

.text-solitud {
  color: #808080;
}

.page-title-right2 {
  display: flex;
  align-items: center;
}

.page-title-box .breadcrumb * {
  font-weight: 500 !important;
  font-family: Inter, sans-serif;
  outline: none;
}

.description-text {
  white-space: initial;
  font-size: 12px;
  max-width: 300px;
}

.img-circle {
  border-radius: 50%;
}

.profile-details {
  text-align: center;
}

.switch_profile {
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch_profile .label-text {
  margin-bottom: 0px;
  padding-right: 5px;
  font-size: 13px;
}

.profile-content {
  position: relative;
}

.user-img {
  margin-bottom: 10px;
}

.select2-container {
  width: 100% !important;
}

.popup-w {
  max-width: 700px;
}

.note-editable.card-block {
  max-height: 200px !important;
}

.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: .5rem;
  font-size: .9rem !important;
  line-height: 1.5;
  color: #505d69;
}

.dataTables_length label .custom-select {
  width: auto;
  display: inline-block;
}

.dataTables_filter {
  text-align: right;
}

.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

.dataTables_filter label .form-control {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

.table-responsive>.row {
  margin: 0px;
}

.table-responsive>.row>div[class^="col-"]:first-child {
  padding-left: 0;
}

.table-responsive>.row>div[class^="col-"]:last-child {
  padding-right: 0px;
}

.popup-inner .card-title {
  font-weight: 500;
  text-transform: initial;
}

.table-responsive .table.mb-0 {
  margin-bottom: 15px !important;
}

.table-responsive .table.mb-0 {
  margin-bottom: 15px !important;
}

.table-responsive .dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
}

.table-responsive .paging_simple_numbers {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.table-responsive .paging_simple_numbers .pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

.page-link:hover {
  color: $primary-color;
}

.filter_boxs {
  display: flex;
  justify-content: flex-end;
}

.dataTables_filter_select {
  margin-right: 15px;
  margin-bottom: .5rem;
}

.dataTables_filter_select .filter_select {
  display: inline-block;
  width: auto;
  border: 1px solid #ced4da;
  outline: none;
}

.button_detail {
  text-align: end;
}

.bg_img {
  height: 35px;
  width: 35px;
  border-radius: 60px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.user_img {
  display: inline-block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  padding-bottom: 50%;
  border-radius: 100%;
}

.btn_style,
.btn_style+.text-danger i:before,
.btn_style,
.btn_style+.text-danger i,
.btn_style {
  line-height: 18px;
}


/***************** Sub Menu *****************/

// #sidebar-menu ul li ul.sub-menu {
//   display: none;
// }

/************************* Start custom css *************************/
#sidebar-menu ul li a {
  // font-size: 14px !important;
}

.select_box {
  display: inline-block;
}

.custom-select {
  cursor: pointer;
}

.gps-location {
  display: block;
  word-break: break-all;
}

/**** User Details ****/
.basic_profile h6,
.basic_profile p {
  margin-bottom: 0px;
}

.basic_profile td:first-child {
  padding-left: 0;
}

.basic_profile td:last-child {
  padding-right: 0;
}

.basic_profile td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.basic_profile tr:first-child td {
  border-top: 0px;
}

.basic_profile p {
  font-size: 12px;
}

.basic_profile h6 {
  font-size: 12px;
}

/**** Schools menu ****/
.school-bg {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  display: inline-block;
  padding-bottom: 70%;
  width: 100%;
  border-radius: 3px;
}

/**** Assessments Details ****/
.btn_success {
  color: #28a745 !important;
}

.question-box.table td,
.question-box.table th {
  padding: 7px;
  font-size: 12px;
}

.question-box.table td {
  vertical-align: middle;
}

.table-responsive .question-row .question-box {
  margin: 0px !important;
}

.section-question .q-title {
  font-weight: bold;
  color: #343a40;
  float: left;
  width: calc(100% - 45px);
  padding-right: 15px;
  // padding-left: 40px;
  padding-left: 1px;
}

.section-question .question_header {
  margin: 0 0 6px;
  display: inline-block;
  width: 100%;
  padding-left: 20px;
}

.section-question .question-row {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #eff2f7;
  position: relative;
}

.section-question .question-row:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.section-question {
  counter-reset: section;
}

.section-question .question-row:before {
  counter-increment: section;
  content: "Q"counter(section) ": ";
  font-weight: bold;
  color: #343a40;
  position: absolute;
  left: 0px;
  top: 0px;
}

.answer-label .action_answer {
  display: flex;
  align-items: center;
  float: left;
  flex-wrap: wrap;
}

.answer-label {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.answer-label:lsat-child {
  margin-bottom: 0px;
}

.answer-label a {
  display: inline-block;
  line-height: 18px;
}

.answer-label a i {
  float: left;
  line-height: 18px;
}

.answer-label .radio-box input {
  position: absolute;
  width: 20px;
  left: 0;
  opacity: 0;
  z-index: 9999;
  height: 20px;
  cursor: pointer;
}


.answer-label .radio-box {
  cursor: pointer;
  width: 20px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  height: 20px;
}

.answer-label .radio-box span:after {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  border: 1px solid #c1c1c1;
}

.answer-label .radio-box span:before {
  border-radius: 60px;
  z-index: 9;
  opacity: 0;

  color: #28a745;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  font-weight: 800;
  font-family: remixicon !important;
  content: "\eb74";
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.answer-label .radio-box span {
  font-size: 0;
}

.answer-label .radio-box input[type=radio]:checked+span:before {
  opacity: 1;
}

.answer-label .answer-label {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.answer-label input {
  width: calc(100% - 60px);
  float: left;
  margin-right: 14px;
}

.b-radius-0 {
  border-radius: 0px !important;
}

.table_page .card-body .table th,
.table_page .card-body .table td {
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
}

.table-style td .text-danger {
  display: inline-block;
  line-height: 12px;
}

.table-style td .text-danger i {
  float: left;
  line-height: 12px;
}


/**** Explore Content ****/
.view-file .video-upload {
  display: inline-block;
  width: 100%;
  outline: none;
}

.view-file iframe.pdf-file {
  width: 100%;
  height: 410px;
}

.view-file .bg_img {
  width: 100%;
  height: auto;
  padding-bottom: 80%;
  border-radius: 0px;
}

/**** Regional Resource Person Details ****/
.school_list .nav-pills .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 1px solid #00000017;
  border-radius: 0px !important;
  margin-bottom: 0px !important;
}

.school_list .nav-pills .nav-link.active {
  background: none !important;
  color: $primary-color !IMPORTANT;
  border-color: $primary-color !IMPORTANT;
  ;
}

.school_list .nav-pills .nav-link .title_school {
  width: calc(100% - 35px);
  display: inline-block;
  padding-right: 15px;
}

// .action_btn i {
//   line-height: 12px;
//   display: inline-block;
// }
// .action_btn i::before {
//   line-height: 14px;
//   display: inline-block;
// }

// .action_btn {
//   display: flex;
//   width: 35px;
//   flex-wrap: wrap;
// }

.school_list .nav-pills .nav-link {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

/**** Regional Managers ****/
.rrp_assigned {
  padding: 0px;
  margin: 0;
  list-style: none;
}

.rrp_assigned li {
  display: inline-block;
  padding-right: 4px;
}

.rrp_assigned li .gps-location {
  display: inline-block;
}

.rrp_assigned li a {
  cursor: pointer;
}

body a.gps-location:hover {
  color: #505d69 !important;
}

.rrp_assigned li:after {
  content: ",";
  font-size: 14px;
  line-height: 5px;
  padding-left: 1px;
}

.rrp_assigned li:last-child:after {
  display: none;
}

.school_list .nav-pills .nav-link:last-child {
  border-bottom: 0px !important;
}

.assigned_school .btn-w {
  padding: 3px 23px;
  margin-left: 0px;
}

.assigned_school .page-title-right2 {
  justify-content: flex-end;
}

// ng-select

.multiple-select ng-select {
  padding: 0px !important;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
  border-top: 0px;
}

.multiple-select .ng-select .ng-select-container {
  border-color: #ced4da !important;
}

.multiple-select .ng-select .ng-select-container:after {
  display: none;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  border-top: 0px !important;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $primary-color;
}

.multiple-select .ng-select .ng-select-container {
  border-color: #ced4da !important;
  min-height: 38.5px !important;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size: 12px;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
  padding-top: 5px !important;
}

.multiple-select .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: $primary-color;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #ffffff;
  background: no-repeat;
  color: #fff !important;
}

.multiple-select .ng-select .ng-arrow-wrapper .ng-arrow {
  border-bottom: 0px !important;
}

.multiple-select .ng-select .ng-arrow-wrapper {
  bottom: auto !important;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper .ng-arrow {
  margin-bottom: 2px;
}

.multiple-select .ng-dropdown-panel.ng-select-bottom {
  box-shadow: none;
}

.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 8px;
}

.multiple-select .ng-select .ng-has-value .ng-placeholder,
.multiple-select .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  transform: translateY(-1.48125em) scale(0.75) perspective(100px) translateZ(0.001px);
}
.question_row .ng-dropdown-panel.ng-select-top {
  top: 101%;
  bottom: auto !important;
}
// .multiple-select .text-btn {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin-bottom: .5rem;
// }

// .multiple-select .text-btn button.clear-all {
//   background: none;
//   border: 0px;
//   color: #fff;
//   background: #ff3d60;
//   border-radius: 35px;
//   padding: 3px 10px;
//   font-size: 13px;
//   line-height: 16px;
// }

// .multiple-select .text-btn label {
//   margin-bottom: 0px;
// }
.multiple-select ng-select .ng-clear-wrapper {
  display: none !important;
}
// .multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value {
//   align-items: baseline !important;
// }



.filter-all .d-flex {
  display: inline-block inherit;
}

.filter-all {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.filter-all .d-flex,
.filter-all .btn-w {
  margin-bottom: .5rem;
}

// .angular_editor ae-select.select-font-size,
// .angular_editor ae-select.select-font,
// .angular_editor button#superscript-,
// .angular_editor button#subscript-,
// .angular_editor button#strikeThrough- {
//   display: none !important;
// }


// .angular_editor .angular-editor-textarea {
//   min-height: 250px !important;
//   outline: none;
// }

/**** Components Details ****/

.modal_header_title {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  line-height: 14px !important;
  position: relative;
}

.modal_header_title .switch_profile {
  right: 16px;
}

.modal_header_title h5 {
  line-height: 24px !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul {
  background: #f5f5f5;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>ul * {
  // color: #000 !important;
}
.multiple-select .ng-dropdown-panel.ng-select-bottom {
  top:100% !important;
}
.multiple-select .ng-dropdown-panel.ng-select-top {
  bottom: calc(100% - -1px);
}

.box-input ul li {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.box-input ul li .input {
    width: calc(100% - 68px);
    display: inline-block;
    border: 0px;
    outline: none;
    background: none;
}

.box-input ul li  .icon-box {
    display: inline-block;
}

.box-input {
    display: inline-block;
    width: 100%;
}

.box-input ul li:last-child {
    margin-bottom: 0px;
}

.box-input ul li.add-option .input {
    width: 80px;
    margin-right: 5px;
}

.box-input .OtherDivider {
    margin-right: 5px;
}

.box-input ul li.add-option .other-exportLabel {
    color: #FC73AE;
    cursor: pointer;
}
.box-input ul li .input:focus {
    border-bottom: 1px solid #FC73AE;
}

// Question form

.question_row {
  background: #fff;
  padding:20px 30px;
}

.question_row .item-dlg-dragHandle {
  cursor: move;
  display: flex;
  justify-content: center;
  opacity: .3;
  height: 24px;
  overflow: hidden;
  width: 24px;
  position: relative;
  margin: auto;
}

.question_row .item-dlg-dragHandle:after {
  content: "";
  display: inline-block;
  background: url(assets/images/qp_sprite139.svg);
  height: 24px;
  background-position: 0 -3479px;
  width: 24px;
  background-size: cover;
}

.ItemTitleRow {
  -webkit-box-align: start;
  box-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  // padding-left: 24px;
  padding-bottom: 18px;
}

.ItemTitleRow .ItemTitleInput {
  -webkit-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  margin-right: 8px;
  margin-bottom: 8px;
}


.upload_file input {
  margin: 0px !important;
  opacity: 0;
  border: 0px !important;
  width: 48px;
  height: 48px;
  z-index: 999;
  position: absolute;

}

.upload_file {
  width:35px;
  position: relative;
  display: inline-block;
  margin-right: 8px;
  border-radius: 60px;
}
.input-action .upload_file.upload_video:after  {
    background-position: 10px -5018px !important;
}
.ItemTitleRow .upload_video:after {
  background-position: 10px -5015px !important;
}
.upload_file:hover {
  background-color: rgba(95, 99, 104, 0.039);
}

.upload_file:after {
  // content: "";
    cursor: pointer !important;
    position: absolute;
    left: 0px;
    width: 33px;
    height: 33px;
    /* background: url(qp_sprite139.svg); */
    /* background-position: 12px -7016px; */
    /* background-size: auto; */
    /* background-repeat: no-repeat; */
    top: 8px;
    content: "\f1d8";
    font-family: remixicon!important;
    font-style: normal;
    text-align: center;
    font-size: 20px;
}

.ItemTitleRow .ItemTitleInput textarea {
  height: 48px;
  border-radius: 0px;
  border: 0px;
  background: #ececec;
  border-bottom: 1px solid #80868b;
  resize: none;
  font-family: 'Google Sans', Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.ng_select_option .ng-select-container .ng-value-container .ng-input>input {
  display: none;
}

.ng_select_option .ng-select .ng-select-container:after {
  display: none;
}

.ng_select_option {
  width: 220px;
}

.ng_select_option .ng-select-container {
  border: 1px solid #dadce0;
}

.ng_select_option .ng-dropdown-panel.ng-select-bottom {
  z-index: +99999 !important;
}

.ng_select_option .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items {
  height: 100% !important;
  max-height: 100vh !important;
}

.ng_select_option .ng-select .ng-clear-wrapper {
  display: none !important;
}

.ng_select_option .ng-select .ng-select-container .ng-value-container {
  margin-top: 0px !important;
  border: 0px !important;
}

.ng_select_option .ng-select .ng-select-container .ng-value-label {
  padding-left: 48px;
  line-height: 22px;
  display: block !important;
  margin-top: 8px !important;
}

.ng_select_option .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding-left: 48px;
}

.ng_select_option .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding-left: 0px;
}


.ng_select_option .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label,
.ng_select_option .ng-value-label {
  background-size: auto;
  background-repeat: no-repeat;
  background: url(assets/images/qp_sprite139.svg);
  background-position: 10px -7020px;
  padding-left: 48px;
  background-repeat: no-repeat;
  background-size: auto;
  line-height: 24px;
  display: block;
  margin-top: 10px;
}

.ng_select_option .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
  bottom: 12px;
}

.ng_select_option .ng-option-label[title="Short answer"],
.ng_select_option .ng-value-label[title="Short answer"] {
  background-position: 10px -6162px !important;
}

.ng_select_option .ng-option-label[title="Paragraph"],
.ng_select_option .ng-value-label[title="Paragraph"] {
  background-position: 10px -832px !important;
}

.ng_select_option .ng-option-label[title="Single choice"],
.ng_select_option .ng-value-label[title="Single choice"] {
  background-position: 10px -6318px !important;
}

.ng_select_option .ng-option-label[title="Multiple choice"],
.ng_select_option .ng-value-label[title="Multiple choice"] {
  background-position: 10px -1118px !important;
}

.ng_select_option .ng-option-label[title="Dropdown"],
.ng_select_option .ng-value-label[title="Dropdown"] {
  background-position: 10px -3692px !important;
}
.ng_select_option .ng-option-label[title="School List"],
.ng_select_option .ng-value-label[title="School List"] {
  background-position: 10px -3692px !important;
}

.ng_select_option .ng-option-label[title="Single choice grid"],
.ng_select_option .ng-value-label[title="Single choice grid"] {
  background-position: 10px -6162px !important;
}

.ng_select_option .ng-option-label[title="Checkbox grid"],
.ng_select_option .ng-value-label[title="Checkbox grid"] {
  background-position: 10px -4498px !important;
}

.ng_select_option .ng-option-label[title="Date"],
.ng_select_option .ng-value-label[title="Date"] {
  background-position: 10px -4134px !important;
}

.ng_select_option .ng-option-label[title="Time"],
.ng_select_option .ng-value-label[title="Time"] {
  background-position: 10px -6162px !important;
}

.ng_select_option .ng-option-label[title="Integer"] ,
.ng_select_option .ng-value-label[title="Integer"] {
  background-position: 10px -2002px !important;
}

.ng_select_option .ng-option-label[title="Image"] ,
.ng_select_option .ng-value-label[title="Image"] {
  background-position: 10px -2730px !important;
}

.ng_select_option .ng-option-label[title="Video"],
.ng_select_option .ng-value-label[title="Video"] {
  background-position: 10px -5018px !important;
}

.ng_select_option .ng-option-label[title="GPS"] {
  background-position: 10px -2730px !important;
}
.ng_select_option .ng-option-label[title="Text"],
.ng_select_option .ng-value-label[title="Text"] {
  background-position: 10px -1144px !important;
}
.ng_select_option .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label[title="Page Break"]{
  background-position: 10px -6005px;
}
.ng_select_option .ng-value-label[title="Page Break"] {
  background-position: 10px -6007px;
}
.ng_select_option .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label[title=GPS],
.ng_select_option .ng-value-label[title=GPS]{background: none;}
.ng_select_option .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label[title=GPS]:before,
.ng_select_option .ng-value-label[title=GPS]:before {
  content: "\f02f";
  font-family: remixicon!important;
  position: absolute;
  left: 10px;
  font-size: 22px;

}
.ng_select_option ng-select {
  padding: 0px !important;
}
.ViewMediaImagePos {
  margin: 0 24px 24px 42px;
  position: relative;
}
.remove-exportLabel {
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
  background-color: #f1f3f4;
  height: 48px;
  border-radius: 50%;
  width: 48px;
  float: left;
  position: absolute;
  left: -12px;
  top: 0px;
  z-index: 99;
}

span.remove-exportLabel:after {
  content: "";
  cursor: pointer !important;
  position: absolute;
  left: 0px;
  width: 48px;
  height: 33px;
  background: url(assets/images/qp_sprite139.svg);
  background-position: 12px -3245px;
  background-size: auto;
  background-repeat: no-repeat;
  top: 8px;
}

.box-input ul li {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.box-input ul li .input {
  width:calc(100% - 68px);
  display: inline-block;
  border: 0px;
  border-bottom: 1px solid #f5f5f5;
  outline: none;
}

.box-input ul li  .icon-box {
  display: inline-block;
}

.box-input {
  display: inline-block;
  width: 100%;
}

.box-input ul li:last-child {
  margin-bottom: 0px;
}

.box-input ul li.add-option .input {
  width: 80px;
  margin-right: 5px;
}

.box-input .OtherDivider {
  margin-right: 5px;
}

.box-input ul li.add-option .other-exportLabel {
  color: $primary-color;
  cursor: pointer;
}
.box-input ul li .input:focus {
  border-bottom: 1px solid $primary-color;
}
.box-input ul li .ViewMediaImagePos {
  margin: 10px 0px 0px 0px;
  max-width: 140px;
  display: inline-block;
}

.box-input ul li .ViewMediaImagePos .remove-exportLabel {
  height: 40px;
  width: 40px;
  left: auto;
  right: -15px;
  top: -4px;z-index: 9;
}

.box-input ul li .ViewMediaImagePos .remove-exportLabel:after {
  width: 30px;
  height: 20px;
  background-size: 22px;
  background-position: 9px -2748px;
}
.box-input ul li {
  padding-left:32px;
  position: relative;
}
.box-input ul li .input-row {
  display: flex;
  flex-wrap: wrap;
}
.box-input ul li .checkbox-box {
  position: absolute;
  left: 0px;
}
.multiple-choice li:before {width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px; content: "";
  background: #ffff;
  border-radius: 60px;
  border: 1px solid #c1c1c1;}
.checkboxes-choice li:before {     top: 0px;
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #c1c1c1;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  }
  .multiple-choice .radio-box {
    width: 20px;
    position: relative;
    cursor: pointer;
    margin-bottom: 0px;
    height: 20px;
    display: inline-block;
}

.multiple-choice .radio-box span {
    font-size: 0px;
}

.multiple-choice .radio-box span:after {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 0px;
    background: #ffff;
    border-radius: 60px;
    border: 1px solid #616161;
}

.multiple-choice  .radio-box span:before {
    content: "";
    width: 11px;
    height: 11px;
    display: inline-block;
    position: absolute;
    left: 4px;
    top: 4px;
    background: #616161;
    border-radius: 60px;
    z-index: 9;
    opacity: 0;
}

.multiple-choice  .radio-box input {
    position: absolute;
    width: 20px;
    left: 0px;
    opacity: 0;
    z-index: +9999;
    height: 20px;
    cursor: pointer;
}

.multiple-choice .radio-box input[type=radio]:checked+span:before {
    opacity: 1;
}


.multiple-choice ul li .radio-box {
position: absolute;
left: 0px;
}
.box-input ul {
  margin: 0px;
  padding: 0px;
}
.input-action {
  display: flex;
}

.input-action .upload_file:after {
  top: 0px;
  height: 24px;
  background-position: 12px -7020px;width: 46px;
}

.input-action .upload_file:hover {
  background: none;
}

.input-action .upload_file input {
  height: 24px;
}

.input-action .iconbuttonContentSpan {
  cursor: pointer;
}
.input-action .upload_file {
  opacity: 0 !IMPORTANT;
}

.box-input ul li:hover .upload_file {
  opacity: 1 !IMPORTANT;
}
.question_row .ng-dropdown-panel.ng-select-bottom {
  top: 101%;
}
.integer-choice  ul li {
  padding-left: 0px;
  max-width: 120px;
  margin-right: 15px;
}

.integer-choice ul li .input {
  width: 100%;
}

.short-answer ul li,.gps-choice ul li {
  padding-left: 0px;
}

.question_row .ng-dropdown-panel .ng-dropdown-panel-items {
  height: 100%;
  max-height: 100vh;
}

.question_row .ng-dropdown-panel.ng-select-top {
  bottom: 101%;
}
.image-choice ul li .input-action .upload_file,
.video-choice ul li .input-action .upload_file {
  opacity: 1 !important;
}
.image-choice ul li  .ViewMediaImagePos,
.video-choice ul li  .ViewMediaImagePos {
  width: 100%;
  max-width: 100%;
  margin-top: 30px;
}
.video-choice ul li .video-upload {
  width: 100%;
}
.video-choice  .upload_file:after {
  background-position: 10px -5018px !important;
}
.video-choice ul li,
.image-choice ul li{padding-left: 0px;}

.popup_assessment .modal-dialog {
  max-width: 730px;
}
// .add_detail_assessment .modal-dialog .modal-content {
//   height: 80%;
// }
.question_row button.btn-warning {
  margin-top: 30px;
}

.popup_assessment .marks {
  position: absolute;
  right: 110px;    width: 175px;
}

.popup_assessment .marks label {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0px;
}

.popup_assessment .marks label input {
  width: 60px;
  margin-left: 6px;
}


.input-group-reset .custom-select-sm {
  padding-left: .5rem;
  padding-right: .5rem;
}
.date_group input {
  width: 167px !important;
}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  min-width: 100% !important;
  width: 20px !important;
  height: auto !important;
  line-height: 10px !important;
}


/**** responsive ****/
@media (max-width:1280px) {}

@media (min-width: 992px) {
  .chat-leftsidebar {
    min-width: 300px;
  }
  .page-title-right2 .switch_profile {
    flex-wrap: wrap;
    text-align: left;
}

}

@media (max-width: 992px) {
  .custom_file {
    margin-bottom: 1rem !important;
  }

  /**** Start custom css *****/
  .vertical-collpsed .vertical-menu #sidebar-menu>ul ul.show {
    display: block !important;background: none;
}
}

@media (max-width:769px) {
  .table-responsive .table thead {
    display: none;
  }

  .table-responsive .table tbody td {
    display: block;
    text-align: end;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;
  }

  .table-responsive .table tbody td .user-icon {
    float: none;
  }

  .table-responsive .table tbody td:before {
    content: attr(data-title)":";
    position: absolute;
    left: 5px;
    color: #505d69;
    font-weight: 600;
    font-family: Nunito, sans-serif;
  }

  .table-responsive .table tbody td[data-title="Labels"] {
    height: 40px;
  }

  .table-responsive .table tbody td:first-child {
    border-top: 0px;
  }

  .description-td .description-text {
    margin-left: auto;
  }

  .table-responsive .table tbody td:last-child {
    border-bottom: 1px solid #c9cbce;
  }

  .table-responsive .table tbody tr:last-child td:last-child {
    border-bottom: 0px;
  }

  .dataTables_length label,
  .dataTables_filter,
  .table-responsive .dataTables_info {
    text-align: center;
    width: 100%;
  }

  .table-responsive>.row>div[class^="col-"] {
    padding: 0px;
  }

  .filter_boxs {
    flex-wrap: wrap;
  }

  .dataTables_filter_select {
    margin-right: 0px;
    width: 100%;
    text-align: center;
  }

  .table-style td[data-title="Picture"],
  .table-style td[data-title="Name"] {
    width: 100%;
  }

  .table-responsive .table tbody td {
    // padding-left: 85px !important;
    padding-left: 140px !important;
    min-height: 43px;
  }

  /**** Start custom css *****/
  .school-bg {
    width: 90px !important;
    height: 90px !important;
    padding: 0 !important;
  }

  .rrp-activity .school-bg {
    height: 55px;
    width: 60px;
  }

  .bg_img {
    margin-left: auto;
  }

  .filter-all {
    flex-wrap: wrap;
    justify-content: center;
  }

  .dataTables_filter .btn-w {
    margin-bottom: 10px;
  }

  .assessment_box .page-title-right2 {
    flex-wrap: wrap;
  }

  .assessment_box .page-title-right2 .switch_profile {
    margin-bottom: 6px;
  }

}


@media (max-width:479px) {
  .filter_s {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }


  .filter_s .select_box_text {
    width: 100%;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }

  .filter_s .select_box select {
    font-size: 10px;
  }

  .filter_s .ml-3 {
    margin-left: 0px !important;
  }

  // .table-responsive .table tbody td {
  //   padding-left: 85px;
  // }

  /**** Start custom css *****/
  .details-page h4.card-title {
    text-align: center;
  }

  .details-page .dataTables_filter button.btn {
    margin-left: 0px;
  }

  .details-page .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .assigned_school .page-title-right2 {
    justify-content: center;
  }

  .filter-all .d-flex .page-title-right2 {
    flex-wrap: wrap !important;
    justify-content: center;
    align-items: baseline;
  }


  .filter-all .d-flex .page-title-right2 .select_box {
    margin-bottom: 10px;
  }

  .table-responsive .table tbody td {
    word-break: break-all;
  }

  .section-assessment .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .assessment_box .page-title-right2 {
    justify-content: left !important;
  }

  .assessment_box .page-title-right2 button.btn:first-child {
    margin-left: 0px;
  }

  .section-question .q-title {
    font-size: 12px;
    line-height: 16px;
  }

  .assessment_box .page-title-right2 .switch_profile {
    width: 100%;
    justify-content: end;
  }

  .switch_right + button.btn {
    float:left !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .question_row {
    padding: 0px !important;
}

.assessment_box .edit-form {
  flex-wrap: wrap;
}

.assessment_box .edit-form .form-group {
  width: 100%;
  padding: 0px !important;
}

.assessment_box .edit-form .form-group:last-child label {
  display: none;
}
.popup_assessment .switch_profile {
  position: initial !important;
}

.popup_assessment .switch_profile span.marks {
  position: inherit !important;
  margin-left: 15px;
}
}



@media (max-width:379px) {

  .table-responsive .table tbody td {
    // padding-left: 115px;
    font-size: 13px;
  }

  .filter_s .select_box select {
    margin-bottom: 10px;
    line-height: 18px;
  }

  .filter_s .select_box+.select_box {
    float: left;
    width: calc(50% - 5px);
  }

  .filter_s .select_box:last-child {
    margin-left: 5px;
  }

  /**** Start custom css *****/
  .details-page .breadcrumb-item+.breadcrumb-item {
    padding-left: 0px;
  }
}


//
.metismenu li {
  outline: none!important;
}
.notFound{
  text-align: center;
  color: gray;
  font-weight: inherit;
}

.table-width {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.cke_combo__font,.cke_combo__fontsize,.cke_combo__styles,.cke_button__flash,.cke_button__table,.cke_button__horizontalrule,.cke_button__smiley,.cke_button__specialchar,.cke_button__pagebreak, .cke_button__iframe  {
  display: none !important;
}
.cke_button__about,.cke_toolbox .cke_toolbar:nth-child(1), .cke_toolbox .cke_toolbar:nth-child(2), .cke_toolbox .cke_toolbar:nth-child(3), .cke_toolbox .cke_toolbar:nth-child(4),.cke_toolbox .cke_toolbar:nth-child(13) {
  display: none !important;
}
.cke_toolbar_break {
  clear: initial !important;
}
button i {
  outline: none !important;
}

.modal-image img {
  width: 100%;
}

.button#insertVideo- {
  display: none!important;
}

.cdk-drop-list .drag-box {
  cursor: move;
}
.modal-orderList th,.modal-orderList td {
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 6px;
  padding-right: 6px;
}
.cdk-drag-placeholder {box-sizing: border-box;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 4px;
}

.cdscroll th, .cdscroll td {
  font-size: 12px;
}
// .cards {
//   margin-bottom: 24px;
//   -webkit-box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
//   box-shadow: 0 2px 4px rgb(0 0 0 / 85%);
// }
.changeOrder{
  margin-top: 3%;
  float: right;
}
div#changeOrder .cdk-drag-placeholder ,
div#change-Order .cdk-drag-placeholder {
  box-shadow: none;
  padding-left: 12px;
  padding-right: 12px;
}

div#changeOrder .cdk-drag-placeholder .card ,
div#change-Order .cdk-drag-placeholder .card {
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
}

#sidebar-menu ul li a {
  // font-size: 14px!important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.angular-editor-textarea {
  outline: none!important;
}



.api-inline-loader {
  display: block;
  text-align: center;
  margin-top: 30px;


}

.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value {
    align-items: baseline !important;
}

.video_box:after {
  position: absolute;
  content: "";
  left: 0px;
  width: 100%;
  height: 100%;
}

.video_box {
  position: relative;
}

.multiple-select .ng-select.ng-select-single .ng-select-container .ng-value-container {
  border: 0px !important;
  padding-left: 10px;
}




/**** Ng Select ****/
.multiple-select-1 .form-group {
  padding: 0px !important;
}

.multiple-select-1 .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
  border-top: 0px;
}

.multiple-select-1 .ng-select .ng-select-container {
  border-color: #ced4da !important;
}

.multiple-select-1 .ng-select .ng-select-container:after {
  display: none;
}

.multiple-select-1 .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  border-top: 0px !important;
}

.multiple-select-1 .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #FF6D41;
  padding: 0px;
  margin: 0 3px 3px 0;
}

.multiple-select-1 .ng-select.ng-select-multiple .ng-select-container.ng-has-value {
  align-items: baseline;
}

.multiple-select-1 .ng-select .ng-select-container {
  border-color: #ced4da !important;
  min-height: 34px !important;
}

.multiple-select-1 .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  line-height: 20px;
  min-height: 20px;
  padding: 5px 10px;
}

.multiple-select-1 .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: #FF6D41;
}

.multiple-select-1 .ng-dropdown-panel.ng-select-bottom {
  top: 101%;
}

.multiple-select-1 .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size: 10px;
}

.multiple-select-1 .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
  padding-top: 5px !important;
}

.multiple-select-1 .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: #FF6D41;
}

.multiple-select-1 .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #ffffff;
  background: no-repeat;
  color: #fff !important;
}

.multiple-select-1 .ng-select .ng-arrow-wrapper {
  top: 0px !important;
  bottom: auto !important;
  height: 28px;
}

.multiple-select-1 .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper .ng-arrow {
  margin-bottom: 2px;
}

.multiple-select-1 .ng-dropdown-panel.ng-select-bottom {
  box-shadow: none;
}

.multiple-select-1 .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 8px;
}

.multiple-select-1 .ng-select .ng-has-value .ng-placeholder,
.multiple-select-1 .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  transform: translateY(-1.48125em) scale(0.75) perspective(100px) translateZ(0.001px);
}

.multiple-select-1 {
  display: inline-block;
  width: 100%;
}

.multiple-select-1 .form-group {
  margin-right: 15px;
  float: left;
  width: calc(33.33% - 10px);
}

.multiple-select-1 .form-group:last-child {
  margin-right: 0px;
}

.multiple-select-1 .ng-select.ng-select-single .ng-select-container .ng-value-container {
  border: 0px !important;
  padding-left: 10px;
}

.multiple-select-1 .ng-optgroup .ng-option-label {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: bold;
}

.multiple-select-1 .ng-optgroup.ng-option-disabled {
  height: auto !important;
  line-height: 32px !important;
  color: #fff !important;
  background-color: #44423F;
}

.multiple-select-1 ng-select {
  padding-bottom: 0px !important;
}